<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 기본정보 -->
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="resultData"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
            <c-btn 
              v-if="editable && isOld" 
              label="삭제" 
              icon="remove" 
              @btnClicked="deleteData"/>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-hazard-equip
                :required="true"
                :editable="editable"
                :disabled="isOld"
                label="유해위험기계기구"
                name="hhmHazardousMachineryId"
                v-model="resultData.hhmHazardousMachineryId"
                @dataChange="dataChange">
              </c-hazard-equip>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 검사일 -->
              <c-datepicker
                :required="true"
                :editable="editable"
                default="today"
                label="검사일"
                name="inspectionDate"
                v-model="resultData.inspectionDate"
              >
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 주관부서 -->
              <c-dept
                :editable="editable"
                label="주관부서"
                type="edit"
                name="departmentDeptCd"
                v-model="resultData.departmentDeptCd"
              >
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 기관(검사원명) -->
              <c-text
                :editable="editable"
                label="기관(검사원명)"
                name="inspectionAgency"
                v-model="resultData.inspectionAgency">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!-- 검사결과 상세 -->
              <c-text
                :editable="editable"
                label="검사결과 상세"
                name="inspectionResultDetail"
                v-model="resultData.inspectionResultDetail">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="editable"
                label="첨부파일">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- 필수항목 목록 -->
        <c-table
          ref="table3"
          title="LBL0002557"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="resultData.resultList"
          :filtering="false"
          :isExcelDown="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="hazardousMachineryInspectionResultItemId"
        >
          <template v-slot:suffixTitle>
            <font style="font-size:0.8em;font-weight:300;">
              &nbsp;&nbsp;
              {{$label('LBL0002577')}} &nbsp;&nbsp;
              {{$label('LBL0002578')}} &nbsp;&nbsp;
              {{$label('LBL0002579')}} &nbsp;&nbsp;
              {{$label('LBL0002580')}} &nbsp;&nbsp;
            </font>
          </template>
          <template v-slot:customArea="{ props }">
            <template v-if="props.row.testMothod">
              <q-chip
                v-for="(name, index) in $_.split(props.row.testMothodName, ',')"
                :key="index"
                outline square 
                color="orange"
                text-color="white"
                class="q-ma-none"
                >
                {{name + ' '}}
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "hazard-equipment-result-register-detail",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        inspectionNo: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      resultData: {
        hhmHazardousMachineryId: null,
        inspectionDate: null,
        departmentDeptName: null,
        departmentDeptCd: null,
        inspectionAgency: null,
        inspectionResultDetail: null,
        inspectionResultCd: null,
        resultList: [],
      },
      disabled: false,
      grid: {
        columns: [
          {
            name: "itemName",
            field: "itemName",
            // 검사항목
            label: "LBL0002558",
            style: 'width:150px',
            align: "center",
            sortable: false,
          },
          {
            name: "itemCriterion",
            field: "itemCriterion",
            // 판정기준
            label: "LBL0002561",
            style: 'width:400px',
            align: "left",
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "inspectionFlag",
            field: "inspectionFlag",
            // 점검(판정)결과
            label: "LBL0002562",
            style: 'width:150px',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '합격' },
              { code: 'N', codeName: '불합격' },
              { code: 'E', codeName: '해당없음' },
            ]
          },
          {
            name: 'testMothod',
            field: 'testMothod',
            // 검사방법
            label: 'LBL0002563',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'useEquip',
            field: 'useEquip',
            // 활용장비
            label: 'LBL0002564',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'testResult',
            field: 'testResult',
            // 검사결과
            label: 'LBL0002565',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 조항
            label: 'LBL0002560',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '850px'
      },
      getUrl: "",
      saveUrl: '',
      deleteUrl: '',
      saveType: 'POST',
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HAZARD_EQUIPMENT',
        taskKey: '',
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
      this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld() {
      return Boolean(this.popupParam.inspectionNo)
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.history.get.url;
      this.getNewUrl = selectConfig.sop.hhm.hazard.history.newitem.url;
      this.saveUrl = transactionConfig.sop.hhm.hazard.history.insert.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.history.insert.url;
      this.updateUrl = transactionConfig.sop.hhm.hazard.history.update.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.history.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.inspectionNo) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.inspectionNo);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.resultData = this.$_.clone(_result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.inspectionNo)
        },);
      } 
    },
    saveData() {
      if (this.popupParam.inspectionNo) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.resultData.regUserId = this.$store.getters.user.userId;
              this.resultData.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          })
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.popupParam.inspectionNo = result.data
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.inspectionNo);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    dataChange(data) {
      this.$http.url = this.getNewUrl
      this.$http.type = 'GET';
      this.$http.param = {
        machineryClassification: data.machineryClassification
      }
      this.$http.request((_result) => {
        this.$set(this.resultData, 'resultList', _result.data)
      },);
    }
  },
};
</script>
